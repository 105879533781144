import Home from 'pages/Home';
import What from 'pages/What';
import Videos from 'pages/Videos';

const home = {
  title: {
    en: 'Home',
    ta: 'முகப்புப்பக்கம்',
  },
  href: '/',
  component: Home,
};

const whatIsIt = {
  title: {
    en: 'What is it?',
    ta: 'என்ன அது?',
  },
  href: '/what-is-it',
  component: What,
};

const videos = {
  title: {
    en: 'Videos',
    ta: 'வீடியோக்கள்',
  },
  href: '/videos',
  component: Videos,
};

const whereToGetHelp = {
  title: {
    en: 'Help',
    ta: 'உதவி',
  },
  href: '/#',
};

const moreResources = {
  title: {
    en: 'More resources',
    ta: 'அதிக வளங்கள்',
  },
  href: '/#',
};

// const links = [home, whatIsIt, whereToGetHelp, moreResources];

const localiseLink = (language) => (link) => ({
  title: link.title[language],
  href: `/${language}${link.href}`,
});

const allLinks = [home, whatIsIt, videos, whereToGetHelp, moreResources];

const localisedLinks = (language) => {
  const localiseLinkFunc = localiseLink(language);
  return allLinks.map((link) => localiseLinkFunc(link));
};

export {
  localiseLink,
  localisedLinks,
  allLinks,
  home,
  whatIsIt,
  videos,
  whereToGetHelp,
  moreResources,
};
