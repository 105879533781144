import React from 'react';
import tw from 'twin.macro';

import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Header from 'components/headers/light.js';
import FAQ from 'components/faqs/SingleCol';
import Footer from 'components/footers/MiniCenteredFooter.js';

// const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;
const Container = tw.div`relative`;

const content = {
  heading: {
    en: (
      <>
        What is <HighlightedText>Mental Health?</HighlightedText>
      </>
    ),
    ta: (
      <>
        <HighlightedText>மன ஆரோக்கியம்</HighlightedText> என்றால் என்ன?
      </>
    ),
  },
  subheading: {
    en: '',
    ta: '',
  },
  description: {
    en:
      'Mental health refers to cognitive, behavioral, and emotional well-being. It is all about how people think, feel, and behave. People sometimes use the term “mental health” to mean the absence of a mental disorder.',
    ta:
      'Mental health refers to cognitive, behavioral, and emotional well-being. It is all about how people think, feel, and behave. People sometimes use the term “mental health” to mean the absence of a mental disorder.',
  },
  faqs: {
    en: [
      {
        question: 'Types of mental illness',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        question: 'Symptoms',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        question: 'Stigma',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
    ],
    ta: [
      {
        question: 'மன நோய் வகைகள்',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        question: 'நோய்க்குறி',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        question: 'Stigma',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
    ],
  },
};

export default ({ language }) => {
  return (
    <>
      <AnimationRevealPage disabled>
        <Header language={language} />
        <Container>
          <FAQ
            heading={content.heading[language]}
            subheading={content.subheading[language]}
            description={content.description[language]}
            faqs={content.faqs[language]}
          />
        </Container>
        <Footer language={language} />
      </AnimationRevealPage>
    </>
  );
};
