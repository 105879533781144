import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';

import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Videos from 'components/videos/GridWithFeaturedPost.js';
import Header from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
// import tempVideos from 'helpers/temp-videos.js';

const Container = tw.div`relative`;
const HighlightedText = tw.span`text-primary-500`;

const content = {
  heading: {
    en: (
      <>
        Videos about <HighlightedText>Mental Health</HighlightedText>
      </>
    ),
    ta: (
      <>
        <HighlightedText>மன ஆரோக்கியம்</HighlightedText> பற்றிய வீடியோக்கள்
      </>
    ),
  },
  subheading: {
    en: '',
    ta: '',
  },
};

export default ({ language }) => {
  const [videos, setVideos] = useState();
  useEffect(() => {
    async function fetchVideos() {
      const response = await fetch('https://tamil-mental-health.org/api/videos');
      const fetchedVideos = await response.json();
      setVideos(fetchedVideos.results);
    }
    fetchVideos();
  }, []);

  return (
    <>
      <AnimationRevealPage disabled>
        <Header language={language} />
        <Container>
          <Videos
            subheading=""
            heading={content.heading[language]}
            description=""
            posts={videos}
          />
        </Container>
        <Footer language={language} />
      </AnimationRevealPage>
    </>
  );
};
