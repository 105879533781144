import React from 'react';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/TwoColumnWithVideo.js';
import Features from 'components/features/ThreeColSimple.js';
import Events from 'components/features/Events.js';
import Footer from 'components/footers/MiniCenteredFooter.js';

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const imageCss = tw`rounded-4xl`;

const content = {
  hero: {
    heading: {
      en: (
        <>
          Your mental health <HighlightedText>is important!</HighlightedText>
        </>
      ),
      ta: (
        <>
          உங்கள் <HighlightedText>மன ஆரோக்கியம்</HighlightedText> முக்கியமானது!
        </>
      ),
    },
    blurb: {
      en: [
        'Everybody has mental health and we need to take care of it, just like with our physical health.',
        'Mental health affects how we think, feel and act. It includes our emotional, psychological and social wellbeing. It helps determine how we handle stress, relate to others and make choices.',
        'Every year, one in four of us will experience a mental health problem. Mental health problems can affect our thinking, mood and behaviour.',
        `Experiencing a mental health problem is often upsetting, confusing and frightening – particularly at first. If you become unwell, you may feel that it's a sign of weakness, or that you are 'losing your mind'. You may experience a lot of stigma from others, or even yourself if you’ve been brought up to believe that mental health problems are something to be ashamed of.`,
        'However, in reality, mental health problems are a common human experience. They are nothing to be ashamed of.',
        'This website has been created by members of the Tamil community in Kingston to encourage conversation around mental health, and provide information and support to those seeking it.',
      ],
      ta: [
        'உடல் நலத்தைப் பேணுவதுபோல, ஒவ்வொருவருக்கும் மனநலமும் தேவை, அதை நாங்கள் பேணவேண்டும்.',
        'எப்படி நாங்கள் சிந்திக்கிறோம், உணர்கிறோம், நடக்கிறோம் என்பதை மனநலன் கட்டுப்படுத்துகிறது. அது எமது உணர்வு, உள. சமூக நடைமுறைகளை உள்ளடக்குகிறது. அழுத்தங்களைக் கையாள்வது, மற்றவர்களுடன் நடந்துகொள்வது, தெரிவு செய்வது போன்றவற்றுக்கு உதவுகிறது.',
        'ஒவ்வொரு வருடமும் நான்குபேரில் ஒருவர் மனநலப் பிரசினைகளை அநுபவிக்கக்கூடும். மனநல பிரசினைகள் எமது சிந்தனை, மனநிலை, நடவடிக்கைகளைப் பாதிக்கும்.',
        'மனநலப் பிரசினைகளை அநுபவிக்கும்போது முதலில் மனவருத்தம், குழப்பம், பயம் ஆகியவற்றைக் கொடுக்கும். உங்கள் நலம் குறையும்போது, நீங்கள் பலவீனமாக அல்லது ‘மனதை இழந்தது’ போன்று உணரலாம். மனநலம் குறைதலால் மற்றவர்கள் உங்களைத் தாழ்வாகப் பார்த்தலோ அல்லது உங்கள் கலாசாரத்தில் - நீங்கள் வளர்ந்துவந்த முறையால் இது ஒரு வெட்கப்படவேண்டிய விடயம் என்றோ உணர்வதற்கு இடமுண்டு.',
        'இருப்பினும், உண்மையில் மனநல பிரசினைகள் என்பவை பொதுவான மனித அநுபவமாகும். அதனால் இதில் வெட்கப்பட ஒன்றுமில்லை.',
        'இந்த இணையதளம் கிங்ஸ்டனிலுள்ள தமிழ்ச் சமூகத்தினரால் உருவாக்கப்பட்டது. மனநல சம்பந்தமான கருத்துப் பரிமாற்றங்களை ஏற்படுத்தவும் தேவைப்படுவோருக்கு தகவல்களும் ஆதரவும் வழங்கவுமென்றே உருவாக்கப்பட்டிருக்கிறது.',
      ],
    },
  },
  events: {
    heading: {
      en: (
        <>
          Check out our <HighlightedText>events.</HighlightedText>
        </>
      ),
      ta: (
        <>
          எங்கள் <HighlightedText>நிகழ்வுகளைப்</HighlightedText> பாருங்கள்.
        </>
      ),
    },
  },
  sponsors: {
    heading: {
      en: (
        <>
          Our <HighlightedText>Sponsors.</HighlightedText>
        </>
      ),
      ta: (
        <>
          எங்கள் <HighlightedText>ஸ்பான்சர்கள்.</HighlightedText>
        </>
      ),
    },
  },
};

export default ({ language }) => {
  return (
    <AnimationRevealPage disabled>
      <Hero
        heading={content.hero.heading[language]}
        description={content.hero.blurb[language]}
        imageSrc="https://images.pexels.com/photos/3132388/pexels-photo-3132388.jpeg?auto=format&fit=crop&w=768&q=80"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        language={language}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <Events heading={content.events.heading[language]} />
      <Features
        heading={content.sponsors.heading[language]}
        cards={[
          {
            imageSrc:
              'https://create4mentalhealth.com/wp-content/uploads/2020/08/cropped-Create4mh_logo.png',
            title: 'Create4MentalHealth',
            description: 'Video/Photography services',
            url: 'https://create4mentalhealth.com/',
          },
          {
            imageSrc:
              'https://adhdkingston.org.uk/wp-content/uploads/2020/01/cropped-Fastminds-Logo-1-2.jpg',
            title: 'Fastminds',
            description: 'Lorem ipsum donor amet siti ceali placeholder text',
            url: 'https://adhdkingston.org.uk/',
          },
          {
            imageSrc:
              'https://www.kingston.gov.uk/site/dist/images/site-logo.svg',
            title: 'Kingston Council',
            description: 'Lorem ipsum donor amet siti ceali placeholder text',
            url: 'https://kingston.gov.uk',
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <Footer language={language} />
    </AnimationRevealPage>
  );
};
