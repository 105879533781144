import React from 'react';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings';
import { SectionDescription } from 'components/misc/Typography';
import { ReactComponent as SvgDotPatternIcon } from 'images/dot-pattern.svg';

const HeadingContainer = tw.div`text-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const PostImage = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-56 sm:h-56 bg-center bg-cover rounded-t`}
`;
const PostText = tw.div`flex-1 px-6 py-8`;
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const PostDescription = tw.p``;
const VideoInfo = tw.div`flex`;
const VideoTextInfo = tw.div`text-xs text-gray-600`;
const VideoDescription = tw.div`font-semibold mt-2`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full lg:w-2/3`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${PostImage} {
        ${tw`sm:h-56 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
      }
      ${PostText} {
        ${tw`pl-8 pr-5`}
      }
      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
      }
      ${VideoInfo} {
        ${tw`mt-8 flex items-center`}
      }
      ${VideoDescription} {
        ${tw`mt-0 font-bold text-gray-700 text-sm`}
      }
    `}
`;

const DecoratorBlob1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;

export default ({
  subheading = '',
  heading = 'We love writing.',
  description = '',
  posts = [],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post
                className="group"
                href={`https://www.youtube.com/watch?v=${
                  post.snippet.resourceId.videoId
                }&list=${post.snippet.playlistId}&index=${
                  post.snippet.position + 1
                }`}
              >
                <PostImage
                  imageSrc={
                    post.snippet.thumbnails.high?.url ||
                    post.snippet.thumbnails.default?.url
                  }
                />
                <PostText>
                  <PostTitle>{post.snippet.title}</PostTitle>
                  {/* TODO */}
                  {/* {post.featured && (
                    <PostDescription>
                      {post.snippet.description}
                    </PostDescription>
                  )} */}
                  <VideoInfo>
                    <VideoTextInfo>
                      <VideoDescription>
                        {post.snippet.description}
                      </VideoDescription>
                    </VideoTextInfo>
                  </VideoInfo>
                </PostText>
              </Post>
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
