import 'tailwindcss/dist/base.css';
import 'styles/globalStyles.css';
import React from 'react';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { allLinks } from './helpers/links';

export default function App() {
  const defaultLanguage = 'en';
  const languages = ['ta', 'en'];

  const pathMappings = allLinks
    .filter((link) => link.component)
    .map((link) => ({
      path: link.href,
      component: link.component,
    }));

  const routes = pathMappings.reverse().flatMap((pathMap, i) => [
    ...languages.map((language) => {
      const pathWithLanguage = language
        ? `/${language}${pathMap.path}`
        : pathMap.path;
      return (
        <Route key={i} path={pathWithLanguage}>
          <pathMap.component language={language || defaultLanguage} />
        </Route>
      );
    }),
    <Route path={pathMap.path} key={pathMappings.length}>
      <Redirect to={`/${defaultLanguage}${pathMap.path}`} />
    </Route>,
  ]);

  return (
    <Router>
      <Switch>{routes}</Switch>
    </Router>
  );
}
